import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>Unearthed Arcana</h2>
    <h3><a id="recharge-magic"></a>RECHARGE MAGIC</h3>
    <table border="0" cellPadding="0" cellSpacing="0" width="100%">
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
In this variant, spellcasters don�t have a hard limit on spells per day.
Instead, it takes time to gather the magical energies required to cast a
spell of a particular level, so characters must wait a number of rounds,
minutes, or hours before casting such a spell again. Spells with an
instantaneous duration or a duration measured in rounds can be cast dozens
of times per day. Longer-duration spells cast outside of combat have
recharge times measured in minutes or hours.
    <p>
At its heart, the recharge magic variant is simple: A well-rested
spellcaster can cast any spell he knows or has prepared, but then either
rolls a die to find out how many rounds must pass before spells of that
level become available again (if it�s a <a style={{
        "color": "#579eb6"
      }} href="#general-recharge-spells">general recharge spell</a>) or loses
access to that spell for a specified time (if it�s a <a style={{
        "color": "#579eb6"
      }} href="#specific-recharge-spells">specific recharge
spell</a>). Spell slots are never expended; they just become temporarily
unavailable.
    </p>
    <p>
Recharging doesn�t require any actions and doesn�t provoke attacks of
opportunity. As long as a spellcaster is alive, he naturally recharges his
energy.
    </p>
    <p>
With this variant, all spellcasters effectively become spontaneous casters
like <a style={{
        "color": "#579eb6"
      }} href="sorcerer.html">sorcerers</a>, choosing a spell each round from a list. For sorcerers and
bards, the list is fixed (just as it is for sorcerers and bards who aren�t
using the variant), but other spellcasters set their available menu of
spells when they prepare spells for the day. With the recharge variant,
there�s no reason to prepare the same spell in more than one slot. Because
sorcerers and bards don�t have the flexibility of choosing a new menu of
spells each day, their general recharge times are shorter.
    </p>
    <p>
Some classes, such as the cleric and the druid, have limited spontaneous
spellcasting ability. Such a character can convert any spell on his daily
list into the appropriate spell (such as either <a style={{
        "color": "#579eb6"
      }} href="spellsC.html#cure-light-wounds">cure wounds</a> or <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#inflict-light-wounds">inflict
wounds</a> for a cleric, or <a style={{
        "color": "#579eb6"
      }} href="spellsS.html#summon-natures-ally-i">summon nature�s ally</a> for a druid), but doing so
replaces the converted spell on the cleric�s daily list with the spell
cast in its place. This happens only once per spell level, because after
that point the relevant cure or inflict spell appears on the spell list as
if it had been prepared.
    </p>
    <p>
This variant distinguishes two kinds of spells: spells with general
recharge time (usually no more than a few rounds) and spells with specific
recharge times (which range from minutes to hours).
    </p>
    <h6><a id="recharge-times-for-other-spells"></a>
Recharge Times for Other Spells
    </h6>
    <a style={{
      "color": "#579eb6"
    }} href="#table-spell-recharge-times">Table: Spell Recharge Times</a> includes all standard spells. Each one is
indicated as having either a general recharge time, a specific recharge
time measured in minutes or hours, or a notation that the spell is
prohibited to casters when this variant is used. For spells from other
sources that are not mentioned here, use those on the table as a guide,
keeping the following in mind.
    <p>
Combat-oriented and short-duration spells (those lasting up to 1 round per
level) typically have a general recharge time. Conversely, spells with
longer durations have specific recharge times (the longer the duration,
the longer the recharge time). Any spell with an expensive material
component or an XP component has a general recharge time.
    </p>
    <p>
Enchantments usually have a specific recharge time ranging from 30 minutes
to 12 hours. This limitation prevents characters from simply charming
everyone in the tavern in the space of a few minutes.
    </p>
    <p>
Spells that build things have a specific recharge time measured in hours.
    </p>
    <p>
Divinations typically have a specific recharge time, often in the
neighborhood of 6 hours, to prevent their abuse in the hands of curious
characters.
    </p>
    <p>
Powerful illusions and permanent "trap"-type spells have specific recharge
times of 12 hours to 1 day, unless they�re expensive to cast.
    </p>
    <h5><a id="general-recharge-spells"></a>
GENERAL RECHARGE SPELLS
    </h5>
Spells that have general recharge times are those cast in combat, those
with longer casting times, and those with an experience point or expensive
material component. Once a character has cast a general recharge spell, he
can�t cast another spell of that level until a number of rounds have
passed.
    <p>
      <a style={{
        "color": "#579eb6"
      }} href="#table-general-recharge-times">Table: General Recharge Times</a> tells how many rounds must pass after the
casting of a general recharge spell before another spell of that level
becomes available.
    </p>
    <b><a id="table-general-recharge-times"></a>Table: General Recharge Times</b>
    <table cellSpacing="0" width="40%">
      <tr>
        <th align="left">
Spell Level
        </th>
        <th align="left">
Bard, Sorcerer
        </th>
        <th align="left">
          <p>{`Cleric, Druid, Paladin,`}</p>
          <p>{`Ranger, Wizard`}</p>
        </th>
      </tr>
      <tr className="odd-row">
        <td>
Highest possible
        </td>
        <td>
          <p>{`1d4+1 rounds`}</p>
        </td>
        <td>
1d6+1 rounds
        </td>
      </tr>
      <tr>
        <td>
Second highest possible
        </td>
        <td>
1d4+1 rounds
        </td>
        <td>
1d6+1 rounds
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Third highest possible
        </td>
        <td>
1d4 rounds
        </td>
        <td>
1d4+1 rounds
        </td>
      </tr>
      <tr>
        <td>
Fourth highest possible
        </td>
        <td>
1d4 rounds
        </td>
        <td>
1d4+1 rounds
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Fifth highest possible
        </td>
        <td>
1d3 rounds
        </td>
        <td>
1d4 rounds
        </td>
      </tr>
      <tr>
        <td>
Sixth highest possible
        </td>
        <td>
1d3 rounds
        </td>
        <td>
1d4 rounds
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Seventh highest possible
        </td>
        <td>
1 round
        </td>
        <td>
1d3 rounds
        </td>
      </tr>
      <tr>
        <td>
Eighth highest possible
        </td>
        <td>
1 round
        </td>
        <td>
1d3 rounds
        </td>
      </tr>
      <tr className="odd-row">
        <td>
Ninth highest possible
        </td>
        <td>
0
        </td>
        <td>
1 round
        </td>
      </tr>
      <tr>
        <td>
Tenth highest possible
        </td>
        <td>
0
        </td>
        <td>
1 round
        </td>
      </tr>
    </table>
    <p>
For example, Boredflak is a 4th-level wizard. His 2nd-level spells (the
highest possible level he can cast) and 1st-level spells (the second
highest possible level he can cast) take 1d6+1 rounds to recharge, and his
0-level spells (the third highest possible level he can cast) take 1d4+1
rounds to recharge. When he attains 5th level, his 3rd-level and 2nd-level
spells take 1d6+1 rounds to recharge and his 1st- and 0-level spells take
1d4+1 rounds to recharge. (Specific recharge spells have their own
recharge times that don�t match these values.)
    </p>
    <p>
Another example: Aliester the 10th-level sorcerer casts <a style={{
        "color": "#579eb6"
      }} href="spellsFtoG.html#fireball">fireball</a> in the
first round of a fight. Once he casts the spell, he rolls 1d4 to determine
his recharge time. He rolls a 2, so he must wait 2 rounds before he
regains access to 3rd-level spells. In the following round, he casts
      <a style={{
        "color": "#579eb6"
      }} href="spellsC.html#cloudkill">cloudkill</a>, one of his highest-level spells. Rolling a 4 on 1d4, Aliester
is dismayed to learn he must wait 5 (1d4+1) rounds before he can cast
cloudkill again. In the next round, he casts <a style={{
        "color": "#579eb6"
      }} href="spellsMtoO.html#magic-missile">magic missile</a>, rolling 1d3
afterward and getting a result of 1 for a 1-round recharge time. In the
following round, Aliester regains access to his 3rd-level spells, so he
can cast another fireball if he likes, or he can cast magic missile again
(because the 1-round recharge time for his 1st-level spells has elapsed).
    </p>
    <h6><a id="metamagic-and-general-recharge-times"></a>
Metamagic and General Recharge Spells
    </h6>
Spellcasters who prepare spells simply put the metamagic versions of the
spells they want in the appropriate spell slots, and they�re cast at their
effective level. For example, casting a <a style={{
      "color": "#579eb6"
    }} href="featsAll.html#quicken-spell">quickened</a> <a style={{
      "color": "#579eb6"
    }} href="spellsMtoO.html#magic-missile">magic missile</a> forces a
spellcaster to recharge her 5th-level spells, not her 1st-level spells.
Sorcerers and bards take a full-round action to add metamagic to any spell
they know, and casting that spell forces a recharge of the effective level
of the metamagic spell. For example, an <a style={{
      "color": "#579eb6"
    }} href="featsAll.html#extend-spell">extended</a> <a style={{
      "color": "#579eb6"
    }} href="spellsHtoL.html#haste">haste</a> spell cast by a
sorcerer would require a recharge roll for a 4th-level spell.
    <h5><a id="specific-recharge-spells"></a>
SPECIFIC RECHARGE SPELLS
    </h5>
Most long-duration utility spells have specific recharge times so
spellcasters can�t just cast them on everyone they meet. These might range
from a mere 5 minutes (for <a style={{
      "color": "#579eb6"
    }} href="spellsAtoB.html#bulls-strength">bull�s strength</a>) to 4 hours (for <a style={{
      "color": "#579eb6"
    }} href="spellsTtoZ.html#teleport">teleport</a>) to a
full day, or 24 hours (for <a style={{
      "color": "#579eb6"
    }} href="spellsTtoZ.html#wind-walk">wind walk</a>). A specific recharge time is the
time that must elapse before a character can cast that particular spell
again; he can cast another spell of the same level in the following round
if he likes.
    <h6><a id="metamagic-and-specific-recharge-spells"></a>
Metamagic and Specific Recharge Spells
    </h6>
Not only do specific recharge spells use higher slots if metamagic feats
have been applied to them, but each +1 to the effective level of the spell
doubles the recharge time. For example, a <a style={{
      "color": "#579eb6"
    }} href="featsAll.html#silent-spell">silent</a> <a style={{
      "color": "#579eb6"
    }} href="spellsC.html#charm-person">charm person</a> spell takes
up a 2nd-level spell slot and has a specific recharge time of 2 hours.
    <h6><a id="countering-and-dispelling"></a>
Countering and Dispelling
    </h6>
If a spell with a specific recharge time is used to counter or dispel
another spell, the specific recharge time does not apply. Instead, treat
the spell as a general recharge spell of that level.
    <p>
For example, if a 5th-level cleric casts <a style={{
        "color": "#579eb6"
      }} href="spellsDtoE.html#deeper-darkness">deeper darkness</a> to dispel a
      <a style={{
        "color": "#579eb6"
      }} href="spellsDtoE.html#daylight">daylight</a> spell, he can cast another 3rd-level spell (including deeper
darkness) 1d6+1 rounds later. If he instead casts deeper darkness to
darken a passageway, he can�t use deeper darkness again for 24 hours, but
his other 3rd-level spells are available to be cast.
    </p>
    <b><a id="table-spell-recharge-times"></a>Table: Spell Recharge Times</b>
    <table cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tr>
        <td style={{
          "width": "30%"
        }}>
          <table cellSpacing="0" style={{
            "width": "100%"
          }}>
            <thead>
              <tr>
                <th align="left">
Spell Name
                </th>
                <th align="left">
Recharge Time
                </th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <td colSpan="2">
                  <ol>
                    <li>You can�t recast this spell or prepare another spell in that
slot until the subject uses the imbued spell.
                    </li>
                  </ol>
                </td>
              </tr>
            </tfoot>
            <tbody>
              <tr className="odd-row">
                <td>
Acid arrow
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
                  <p>{`Acid fog`}</p>
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Acid splash
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Aid
                </td>
                <td>
5 minutes
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Air walk
                </td>
                <td>
1 hour
                </td>
              </tr>
              <tr>
                <td>
Alarm
                </td>
                <td>
4 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Align weapon
                </td>
                <td>
5 minutes
                </td>
              </tr>
              <tr>
                <td>
Alter self
                </td>
                <td>
4 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Analyze dweomer
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Animal growth
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Animal messenger
                </td>
                <td>
6 hours
                </td>
              </tr>
              <tr>
                <td>
Animal shapes
                </td>
                <td>
24 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Animal trance
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Animate dead
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Animate objects
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Animate plants
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Animate rope
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Antilife shell
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Antimagic field
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Antipathy
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Antiplant shell
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Arcane eye
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Arcane lock
                </td>
                <td>
1 hour
                </td>
              </tr>
              <tr>
                <td>
Arcane mark
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Arcane sight
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr>
                <td>
Arcane sight, greater
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Astral projection
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Atonement
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Augury
                </td>
                <td>
6 hours
                </td>
              </tr>
              <tr>
                <td>
Awaken
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Baleful polymorph
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Bane
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Banishment
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Barkskin
                </td>
                <td>
1 hour
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Bear�s endurance
                </td>
                <td>
5 minutes
                </td>
              </tr>
              <tr>
                <td>
Bear�s endurance, mass
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Bestow curse
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Binding
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Black tentacles
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Blade barrier
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Blasphemy
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Bless
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Bless water
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Bless weapon
                </td>
                <td>
5 minutes
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Blight
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Blindness/deafness
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Blink
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Blur
                </td>
                <td>
5 minutes
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Break enchantment
                </td>
                <td>
1 hour
                </td>
              </tr>
              <tr>
                <td>
Bull�s strength
                </td>
                <td>
5 minutes
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Bull�s strength, mass
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr>
                <td>
Burning hands
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Call lightning
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Call lightning storm
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Calm animals
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Calm emotions
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Cat�s grace
                </td>
                <td>
5 minutes
                </td>
              </tr>
              <tr>
                <td>
Cat�s grace, mass
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Cause fear
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Chain lightning
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Changestaff
                </td>
                <td>
6 hours
                </td>
              </tr>
              <tr>
                <td>
Chaos hammer
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Charm animal
                </td>
                <td>
1 hour
                </td>
              </tr>
              <tr>
                <td>
Charm monster
                </td>
                <td>
1 hour
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Charm monster, mass
                </td>
                <td>
1 hour
                </td>
              </tr>
              <tr>
                <td>
Charm person
                </td>
                <td>
1 hour
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Chill metal
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Chill touch
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Circle of death
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Clairaudience/clairvoyance
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Clenched fist
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Cloak of chaos
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Clone
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Cloudkill
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Color spray
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Command
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Command plants
                </td>
                <td>
1 hour
                </td>
              </tr>
              <tr>
                <td>
Command undead
                </td>
                <td>
1 hour
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Command, greater
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Commune
                </td>
                <td>
6 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Commune with nature
                </td>
                <td>
6 hours
                </td>
              </tr>
              <tr>
                <td>
Comprehend languages
                </td>
                <td>
4 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Cone of cold
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Confusion
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Confusion, lesser
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Consecrate
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Contact other plane
                </td>
                <td>
6 hours
                </td>
              </tr>
              <tr>
                <td>
Contagion
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Contingency
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Continual flame
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Control plants
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr>
                <td>
Control undead
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Control water
                </td>
                <td>
1 hour
                </td>
              </tr>
              <tr>
                <td>
Control weather
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Control winds
                </td>
                <td>
4 hours
                </td>
              </tr>
              <tr>
                <td>
Create food and water
                </td>
                <td>
24 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Create greater undead
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Create undead
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Create water
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr>
                <td>
Creeping doom
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Crushing despair
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Crushing hand
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Cure critical wound, mass
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Cure critical wounds
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Cure light wounds
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Cure light wounds, mass
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Cure minor wounds
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Cure moderate wounds
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Cure moderate wounds, mass
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Cure serious wounds
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Cure serious wounds, mass
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Curse water
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Dancing lights
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Darkness
                </td>
                <td>
4 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Darkvision
                </td>
                <td>
6 hours
                </td>
              </tr>
              <tr>
                <td>
Daylight
                </td>
                <td>
4 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Daze
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Daze monster
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Death knell
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Death ward
                </td>
                <td>
5 minutes
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Deathwatch
                </td>
                <td>
4 hours
                </td>
              </tr>
              <tr>
                <td>
Deep slumber
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Deeper darkness
                </td>
                <td>
24 hours
                </td>
              </tr>
              <tr>
                <td>
Delay poison
                </td>
                <td>
6 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Delayed blast fireball
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Demand
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Desecrate
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr>
                <td>
Destruction
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Detect animals or plants
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Detect chaos
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Detect evil
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Detect good
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Detect law
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Detect magic
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Detect poison
                </td>
                <td>
5 minutes
                </td>
              </tr>
              <tr>
                <td>
Detect scrying
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Detect secret doors
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Detect snares and pits
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Detect thoughts
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Detect undead
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Dictum
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Dimension door
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Dimensional anchor
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Dimensional lock
                </td>
                <td>
24 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Diminish plants
                </td>
                <td>
4 hours
                </td>
              </tr>
              <tr>
                <td>
Discern lies
                </td>
                <td>
6 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Discern location
                </td>
                <td>
6 hours
                </td>
              </tr>
              <tr>
                <td>
Disguise self
                </td>
                <td>
4 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Disintegrate
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Dismissal
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Dispel chaos
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Dispel evil
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Dispel good
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Dispel law
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Dispel magic
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Dispel magic, greater
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Displacement
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Disrupt undead
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Disrupting weapon
                </td>
                <td>
5 minutes
                </td>
              </tr>
              <tr>
                <td>
Divination
                </td>
                <td>
6 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Divine favor
                </td>
                <td>
5 minutes
                </td>
              </tr>
              <tr>
                <td>
Divine power
                </td>
                <td>
5 minutes
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Dominate animal
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Dominate monster
                </td>
                <td>
12 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Dominate person
                </td>
                <td>
12 hours
                </td>
              </tr>
              <tr>
                <td>
Doom
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Dream
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Eagle�s splendor
                </td>
                <td>
5 minutes
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Eagle�s splendor, mass
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr>
                <td>
Earthquake
                </td>
                <td>
12 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Elemental swarm
                </td>
                <td>
1 hour
                </td>
              </tr>
              <tr>
                <td>
Endure elements
                </td>
                <td>
6 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Energy drain
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Enervation
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Enlarge person
                </td>
                <td>
5 minutes
                </td>
              </tr>
              <tr>
                <td>
Enlarge person, mass
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Entangle
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Enthrall
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Entropic shield
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr>
                <td>
Erase
                </td>
                <td>
General
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Ethereal jaunt
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr>
                <td>
Etherealness
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Expeditious retreat
                </td>
                <td>
30 minutes
                </td>
              </tr>
              <tr>
                <td>
Explosive runes
                </td>
                <td>
24 hours
                </td>
              </tr>
              <tr className="odd-row">
                <td>
Eyebite
                </td>
                <td>
General
                </td>
              </tr>
              <tr>
                <td>
Fabricate
                </td>
                <td>
4 hours
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td style={{
          "width": "3%"
        }}></td>
        <td style={{
          "width": "30%",
          "verticalAlign": "top"
        }}>
          <table cellSpacing="0" cellPadding="0" style={{
            "width": "100%"
          }}>
            <tr>
              <td colSpan="2">Continued...</td>
            </tr>
            <tr className="odd-row">
              <td>
Faerie fire
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
False life
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
False vision
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Fear
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Feather fall
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Feeblemind
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Find the path
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr>
              <td>
Find traps
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Finger of death
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Fire seeds
              </td>
              <td>
1 hour
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Fire shield
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Fire storm
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Fire trap
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Fireball
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Flame arrow
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Flame blade
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Flame strike
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Flaming sphere
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Flare
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Flesh to stone
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Floating disk
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Fly
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Fog cloud
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Forbiddance
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Forcecage
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Forceful hand
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Foresight
              </td>
              <td>
1 hour
              </td>
            </tr>
            <tr>
              <td>
Fox�s cunning
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Fox�s cunning, mass
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr>
              <td>
Freedom
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Freedom of movement
              </td>
              <td>
1 hour
              </td>
            </tr>
            <tr>
              <td>
Freezing sphere
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Gaseous form
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr>
              <td>
Gate
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Geas, lesser
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Geas/quest
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Gentle repose
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Ghost sound
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Ghoul touch
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Giant vermin
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Glibness
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr>
              <td>
Glitterdust
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Globe of invulnerability
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Globe of invulnerability, lesser
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Glyph of warding
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Glyph of warding, greater
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Good hope
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr>
              <td>
Goodberry
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Grasping hand
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Grease
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Guards and wards
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Guidance
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Gust of wind
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Hallow
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Hallucinatory terrain
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Halt undead
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Harm
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Haste
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Heal
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Heal mount
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Heal, mass
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Heat metal
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Helping hand
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Heroes� feast
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Heroism
              </td>
              <td>
1 hour
              </td>
            </tr>
            <tr>
              <td>
Heroism, greater
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Hide from animals
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr>
              <td>
Hide from undead
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Hideous laughter
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Hold animal
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Hold monster
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Hold monster, mass
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Hold person
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Hold person, mass
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Hold portal
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr>
              <td>
Holy aura
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Holy smite
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Holy sword
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Holy word
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Horrid wilting
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Hypnotic pattern
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Hypnotism
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Ice storm
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Identify
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Illusory script
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Illusory wall
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Imbue with spell ability<sup>1</sup>
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Implosion
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Imprisonment
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Incendiary cloud
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Inflict critical wounds
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Inflict critical wounds, mass
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Inflict light wounds
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Inflict light wounds, mass
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Inflict minor wounds
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Inflict moderate wounds
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Inflict moderate wounds, mass
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Inflict serious wounds
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Inflict serious wounds, mass
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Insanity
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Insect plague
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr>
              <td>
Instant summons
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Interposing hand
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Invisibility
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Invisibility purge
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr>
              <td>
Invisibility, greater
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Invisibility, mass
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr>
              <td>
Iron body
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Ironwood
              </td>
              <td>
12 hours
              </td>
            </tr>
            <tr>
              <td>
Irresistible dance
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Jump
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr>
              <td>
Keen edge
              </td>
              <td>
1 hour
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Knock
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr>
              <td>
Know direction
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Legend lore
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Levitate
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Light
              </td>
              <td>
1 hour
              </td>
            </tr>
            <tr>
              <td>
Lightning bolt
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Limited wish
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Liveoak
              </td>
              <td>
12 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Locate creature
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr>
              <td>
Locate object
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Longstrider
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Lullaby
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Mage armor
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Mage hand
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Mage�s disjunction
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Mage�s faithful hound
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Mage�s lucubration
              </td>
              <td>
Spell is prohibited
              </td>
            </tr>
            <tr>
              <td>
Mage�s magnificent mansion
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Mage�s private sanctum
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Magic aura
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Magic circle against chaos
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr>
              <td>
Magic circle against evil
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Magic circle against good
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr>
              <td>
Magic circle against law
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Magic fang
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr>
              <td>
Magic fang, greater
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Magic jar
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Magic missile
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Magic mouth
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Magic stone
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Magic vestment
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Magic weapon
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Magic weapon, greater
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Major creation
              </td>
              <td>
12 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Major image
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Make whole
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Mark of justice
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Maze
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Meld into stone
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr>
              <td>
Mending
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Message
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Meteor swarm
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Mind blank
              </td>
              <td>
12 hours
              </td>
            </tr>
            <tr>
              <td>
Mind fog
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Minor creation
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Minor image
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Miracle
              </td>
              <td>
12 hours
              </td>
            </tr>
            <tr>
              <td>
Mirage arcana
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Mirror image
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr>
              <td>
Misdirection
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Mislead
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Mnemonic enhancer
              </td>
              <td>
Spell is prohibited
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Modify memory
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Moment of prescience
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Mount
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Move earth
              </td>
              <td>
1 hour
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Neutralize poison
              </td>
              <td>
1 hour
              </td>
            </tr>
            <tr>
              <td>
Nightmare
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Nondetection
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Obscure object
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Obscuring mist
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Order�s wrath
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Overland flight
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Owl�s wisdom
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Owl�s wisdom, mass
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr>
              <td>
Pass without trace
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Passwall
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Permanency
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Permanent image
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Persistent image
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Phantasmal killer
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Phantom steed
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Phantom trap
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Phase door
              </td>
              <td>
12 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Planar ally
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Planar ally, greater
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Planar ally, lesser
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Planar binding
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Planar binding, greater
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Planar binding, lesser
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Plane shift
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Plant growth
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Poison
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Polar ray
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Polymorph
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr>
              <td>
Polymorph any object
              </td>
              <td>
12 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Power word blind
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Power word kill
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Power word stun
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Prayer
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Prestidigitation
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Prismatic sphere
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Prismatic spray
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Prismatic wall
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Produce flame
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Programmed image
              </td>
              <td>
12 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Project image
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Protection from arrows
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Protection from chaos
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr>
              <td>
Protection from energy
              </td>
              <td>
1 hour
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Protection from evil
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr>
              <td>
Protection from good
              </td>
              <td>
5 minutes
              </td>
            </tr>
          </table>
        </td>
        <td style={{
          "width": "3%"
        }}></td>
        <td style={{
          "width": "30%",
          "verticalAlign": "top"
        }}>
          <table cellSpacing="0" cellPadding="0" style={{
            "width": "100%"
          }}>
            <tr>
              <td colSpan="2">Continued...</td>
            </tr>
            <tr className="odd-row">
              <td>
Protection from law
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr>
              <td>
Protection from spells
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Prying eyes
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Prying eyes, greater
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Purify food and drink
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr>
              <td>
Pyrotechnics
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Quench
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Rage
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Rainbow pattern
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Raise dead
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Ray of enfeeblement
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Ray of exhaustion
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Ray of frost
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Read magic
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Reduce animal
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Reduce person
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Reduce person, mass
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr>
              <td>
Refuge
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Regenerate
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Reincarnate
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Remove blindness/deafness
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Remove curse
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Remove disease
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Remove fear
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Remove paralysis
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Repel metal or stone
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Repel vermin
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr>
              <td>
Repel wood
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Repulsion
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Resilient sphere
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Resist energy
              </td>
              <td>
1 hour
              </td>
            </tr>
            <tr>
              <td>
Resistance
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Restoration
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Restoration, greater
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Restoration, lesser
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Resurrection
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Reverse gravity
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Righteous might
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Rope trick
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Rusting grasp
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Sanctuary
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr>
              <td>
Scare
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Scintillating pattern
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Scorching ray
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Screen
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Scrying
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Scrying, greater
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Sculpt sound
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Searing light
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Secret chest
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Secret page
              </td>
              <td>
12 hours
              </td>
            </tr>
            <tr>
              <td>
Secure shelter
              </td>
              <td>
12 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
See invisibility
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr>
              <td>
Seeming
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Sending
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Sepia snake sigil
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Sequester
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Shades
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Shadow conjuration
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Shadow conjuration, greater
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Shadow evocation
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Shadow evocation, greater
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Shadow walk
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Shambler
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Shapechange
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr>
              <td>
Shatter
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Shield
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr>
              <td>
Shield of faith
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Shield of law
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Shield other
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Shillelagh
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr>
              <td>
Shocking grasp
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Shout
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Shout, greater
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Shrink item
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Silence
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Silent image
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Simulacrum
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Slay living
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Sleep
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Sleet storm
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Slow
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Snare
              </td>
              <td>
12 hours
              </td>
            </tr>
            <tr>
              <td>
Soften earth and stone
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Solid fog
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Song of discord
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Soul bind
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Sound burst
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Speak with animals
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr>
              <td>
Speak with dead
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Speak with plants
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr>
              <td>
Spectral hand
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Spell immunity
              </td>
              <td>
1 hour
              </td>
            </tr>
            <tr>
              <td>
Spell immunity, greater
              </td>
              <td>
1 hour
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Spell resistance
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr>
              <td>
Spell turning
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Spellstaff
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Spider climb
              </td>
              <td>
1 hour
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Spike growth
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Spike stones
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Spiritual weapon
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Statue
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Status
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Stinking cloud
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Stone shape
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Stone tell
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Stone to flesh
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Stoneskin
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Storm of vengeance
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Suggestion
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Suggestion, mass
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr>
              <td>
Summon instrument
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Summon monster I
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Summon monster II
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Summon monster III
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Summon monster IV
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Summon monster V
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Summon monster VI
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Summon monster VII
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Summon monster VIII
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Summon monster IX
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Summon nature�s ally I
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Summon nature�s ally II
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Summon nature�s ally III
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Summon nature�s ally IV
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Summon nature�s ally V
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Summon nature�s ally VI
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Summon nature�s ally VII
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Summon nature�s ally VIII
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Summon nature�s ally IX
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Summon swarm
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Sunbeam
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Sunburst
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Symbol of death
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Symbol of fear
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Symbol of insanity
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Symbol of pain
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Symbol of persuasion
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Symbol of sleep
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Symbol of stunning
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Symbol of weakness
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Sympathetic vibration
              </td>
              <td>
12 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Sympathy
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Telekinesis
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Telekinetic sphere
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Telepathic bond
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Teleport
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr>
              <td>
Teleport object
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Teleport, greater
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr>
              <td>
Teleportation circle
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Temporal stasis
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Time stop
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Tiny hut
              </td>
              <td>
12 hours
              </td>
            </tr>
            <tr>
              <td>
Tongues
              </td>
              <td>
1 hour
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Touch of fatigue
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Touch of idiocy
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Transformation
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Transmute metal to wood
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Transmute mud to rock
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Transmute rock to mud
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Transport via plants
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr>
              <td>
Trap the soul
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Tree shape
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Tree stride
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
True resurrection
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
True seeing
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
True strike
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr>
              <td>
Undeath to death
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Undetectable alignment
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Unhallow
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Unholy aura
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Unholy blight
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Unseen servant
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr>
              <td>
Vampiric touch
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Veil
              </td>
              <td>
12 hours
              </td>
            </tr>
            <tr>
              <td>
Ventriloquism
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Virtue
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr>
              <td>
Vision
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Wail of the banshee
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Wall of fire
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Wall of force
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Wall of ice
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Wall of iron
              </td>
              <td>
12 hours
              </td>
            </tr>
            <tr>
              <td>
Wall of stone
              </td>
              <td>
12 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Wall of thorns
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Warp wood
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Water breathing
              </td>
              <td>
4 hours
              </td>
            </tr>
            <tr>
              <td>
Water walk
              </td>
              <td>
4hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Waves of exhaustion
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Waves of fatigue
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Web
              </td>
              <td>
5 minutes
              </td>
            </tr>
            <tr>
              <td>
Weird
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Whirlwind
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Whispering wind
              </td>
              <td>
30 minutes
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Wind walk
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Wind wall
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Wish
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Wood shape
              </td>
              <td>
6 hours
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Word of chaos
              </td>
              <td>
General
              </td>
            </tr>
            <tr>
              <td>
Word of recall
              </td>
              <td>
General
              </td>
            </tr>
            <tr className="odd-row">
              <td>
Zone of silence
              </td>
              <td>
24 hours
              </td>
            </tr>
            <tr>
              <td>
Zone of truth
              </td>
              <td>
30 minutes
              </td>
            </tr>
          </table>
        </td></tr></table>
    <h5><a id="behind-the-curtain-recharge-magic"></a>
BEHIND THE CURTAIN: RECHARGE MAGIC
    </h5>
    <div style={{
      "marginLeft": "40px"
    }}>This is a high-powered variant, because spellcasters can cast their staple
combat and healing spells all day long. This means that the PCs face
almost every encounter fully healed, and rarely do spellcasters have their
spell lists significantly depleted. Spellcasters may have some
specific-recharge utility and protective spells that they won�t be able to
cast before every fight, but they always have access to their
highest-level combat and healing spells. And it�s those spells that
largely comprise a spellcaster�s contribution toward overcoming a given
encounter.
      <p>
As with most high-powered variants, recharge magic works only if every
spellcaster in the campaign is using it, including powerful NPC
spellcasters. A recharge-variant lich, for example, is a terror for
high-level PCs to behold.
      </p>
      <p>
As the GM, you have to plan for a party of PCs that can adventure all day
long, beginning every fight in a more or less fully rested state. This
prospect can be thrilling for players, who no longer have to face the
disappointment of a retreat to town just when they�re on the cusp of the
adventure�s climax. But when PCs don�t need to retreat to heal and prepare
new spells, the GM ends up running more encounters per game session, which
means a measure of extra preparation.
      </p>
      <p>
As a rule of thumb, a group of four PCs that includes two recharge variant
spellcasters can handle encounters of an Encounter Level equal to the
characters� average level +2. The fights will be exciting ones; every room
in the dungeon becomes as perilous as a typical adventure�s climax. But
the PCs face each one with full resources, so the denizens of the dungeon
can�t defeat them through depletion and attrition. If your group likes
knock-down, drag-out, set-piece battles, the recharge magic variant lets
you fight more of them.
      </p>
      <p>
Characters attain new levels faster in terms of both player time at the
gaming table and character time in the game world. You can regard this as
an additional benefit, or you can reduce experience point awards across
the board to compensate.
      </p>
      <p>
The recharge magic variant makes spellcasters stronger overall, and it
makes nonspellcasters stronger if you assume they�re getting fully healed
after every encounter. But in some respects it makes them weaker as well.
Even the most powerful wizard can�t cast two <a style={{
          "color": "#579eb6"
        }} href="spellsFtoG.html#fireball">fireball</a> spells in
consecutive rounds with this variant. The <a style={{
          "color": "#579eb6"
        }} href="spellsTtoZ.html#teleport">teleport</a> spell has a specific
recharge time of 4 hours, so a character can�t teleport someplace, cast a
spell, then teleport away again (unless he uses <a style={{
          "color": "#579eb6"
        }} href="spellsTtoZ.html#word-of-recall">word of recall</a> or <a style={{
          "color": "#579eb6"
        }} href="spellsTtoZ.html#greater-teleport">greater
teleport</a> for one of the legs of the journey). Strategies that rely on
repeated use of the same spell aren�t viable if the spell in question has
a specific recharge time. Depending on the strategy, its chance of success
might improve or worsen if a character is relying on a general recharge
spell. In short, the recharge variant gives casters a lot more power in
exchange for slightly less flexibility with regard to general recharge
spells and significantly less flexibility with regard to specific recharge
spells.
      </p>
      <p>
There�s an obvious way for characters to compensate for the diminished
flexibility: scrolls. In general, scrolls become more useful in the
recharge variant game world, so they may be more prevalent. Conversely,
wands are somewhat less useful, because every spellcaster can crank out
his staple spells dozens of times. When a character can cast <a style={{
          "color": "#579eb6"
        }} href="spellsHtoL.html#lightning-bolt">lightning
bolt</a> fifty times in the space of an hour, that fully charged wand of
lightning bolt is much less impressive. If you use the recharge magic
variant, consider reducing the percentages of minor and medium wands on
        <a style={{
          "color": "#579eb6"
        }} href="magicItemsSSW.html#table-wands">Table: Wands</a> by 5% and
increasing the percentages of minor and medium scrolls by the same amount.
      </p>
      <p>
One less obvious change posed by this variant is that lower-level spells
retain more importance for high-level spellcasters. With the general
recharge rules, even the most potent spellcaster can�t use her best spells
more than two or three times in a major fight. Spells two, three, or four
levels below the maximum get cast while the spellcaster waits for the
heavy artillery to get reloaded.
      </p>
      <p>
As the GM, you have to contend with other subtle changes wrought by this
variant. Monsters with spell-like abilities usable at will are diminished
somewhat, because true spellcasters can cast spells so frequently.
Monsters with spell-like abilities aren�t actually weaker � and their
Challenge Ratings needn�t go down as a result � but they feel less special
and more like spellcasters with a static spell list.
      </p></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      